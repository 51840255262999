import { Box, Typography, Link } from "@mui/material";

export default function Contact () {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="50px"
            mb="100px"
        >
            <Typography
            color = "#101010"
            fontFamily= "NTR"
            fontSize= "64px"
            lineHeight= "40px" /* 83.333% */
            letterSpacing= "2.4px"   
            textTransform="uppercase"
            >
                Contact Us
            </Typography>
            <Box>
                <ContactList 
                    contactListMap={'Singjamei Waikhom Leikai \n Imphal East - 795008 \n Manipur, India'}
                    contactListMaphref="https://maps.app.goo.gl/XP9p9F2UottTohC38"
                    contactListPhone="+91 7005144136"
                    contactListPhonehref="tel:+917005144136"
                    contactListEmail="h360pictures@gmail.com"
                    contactListEmailhref="mailto:h360pictures@gmail.com"
                />
            </Box>

        </Box>
    )
}

const ContactList = ({
    contactListMap, contactListMaphref, 
    contactListPhone, contactListPhonehref, 
    contactListEmail, contactListEmailhref
}) => {
    return(
        <Box>
            <Typography
            sx={{
                color: "#101010",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "20px",
                letterSpacing: "1px",
                whiteSpace: 'pre-line'
            }}
            >
                <Link href={contactListMaphref} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                    {contactListMap}
                </Link>
                
            </Typography>
            <Typography
            sx={{
                color: "#101010",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "20px",
                letterSpacing: "1px",
            }}
            >
                <Link href={contactListPhonehref} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                    {contactListPhone}
                </Link>  
            </Typography>
            <Typography
            sx={{
                color: "#101010",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "20px",
                letterSpacing: "1px",
            }}
            >
                <Link href={contactListEmailhref} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                    {contactListEmail}
                </Link>       
            </Typography>
        </Box>
    )
}