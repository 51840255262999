import { Box, Typography } from "@mui/material";

import Footer from "../global/footer";
import Header from "../global/header";
import { Text, Cast, OtherCast, Platform } from "./style";
import { Thumbnail1 } from "../../component";
import { Description } from "../home/style";

import data from "../../data/data.json"
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import './style.css';


export default function Content() {

    const { name } = useParams();
    const album = data.album.find(item => item.name === name);
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);
    return (
        <Box>
            <Header />
            {album && (
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <a href={album.thumbnail.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                        <img src={album.thumbnail.src} alt={album.title} style={{ width: '100vw', maxWidth: "1600px" }} />
                    </a>

                    {/* Brief */}
                    <Box className="brief">
                        <Text title={album.title} />
                        <Text description={album.description} />
                        <Platform
                            spotify={album.links.spotify}
                            ytmusic={album.links.ytm}
                            wynk={album.links.wynk}
                        />
                    </Box>
                    {/* Brief Ends */}

                    {/* Cast Starts */}
                    <Box className="cast">
                        <Typography width="20%"
                            sx={{
                                color: "101010",
                                fontFamily: "Poppins",
                                fontSize: "48px",
                                letterSpacing: "2.4px",
                            }}
                        >
                            The Cast
                        </Typography>

                        <Box className="container">
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                                gap="50px"
                            >
                                {album.cast.detail.map((item, index) => (
                                    <Box key={index} marginRight='auto' span="span 1">
                                        <Cast castaltname="cast1" castsrc={item.profile} castname={item.name} />
                                    </Box>
                                ))}
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                                gap="50px"
                                mb="100px"
                            >
                                {album.member.map((item, index) => (
                                    <Box key={index}>
                                        <OtherCast othercastrole={item.role} othercastname={item.name} />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    {/* Cast Ends */}

                    {/* Special Thanks */}
                    {album.others && (
                        <Box mb="50px">
                        <Text title2="Special Thanks" />
                        {album.others.map((item, index) => (
                            <Box key={index} mb="5px">
                                <Typography
                                    sx={{
                                        color: "808080",
                                        fonFamily: "NTR",
                                        fontSize: "18px",
                                        fontWeight: "300",
                                        letterSpacing: "0.9px",
                                        textAlign: "center"
                                    }}
                                >
                                    {item}
                                </Typography>
                            </Box>
                        ))}
                        </Box>
                    )}

                    {album.bts && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            m="100px 0 100px 0"
                        >
                            <Box width="50%">
                                <a href={album.bts.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                                    <Thumbnail1 src={album.bts.src} title={album.title} />
                                </a>
                            </Box>
                            <Box className="bts_detail">
                                <Typography
                                    mb="100px"
                                    sx={{
                                        color: "101010",
                                        fontFamily: "Poppins",
                                        fontSize: "48px",
                                        letterSpacing: "2.4px",
                                    }}
                                >
                                    Behind The Scene
                                </Typography>
                                <Description style1={album.description} />
                            </Box>
                        </Box>
                    )}

                </Box>)}
            <Footer />

        </Box>

    )
}