import React from 'react';
import { Typography, Box } from '@mui/material';

const Description = ({ style1, style2, style3, style4 }) => {
  return (
    <Box>
      <Typography
        variant="h1"
        fontWeight="550"
        sx={{
          fontFamily: 'Mulish',
          fontSize: '32px',
          lineHeight: '40px',
        }}
      >
        {style1}
      </Typography>
      <Typography
        variant="h3"
        fontWeight="550"
        sx={{
          fontFamily: 'Mulish',
          fontSize: '16px',
        }}
      >
        {style2}
      </Typography>
      <Typography
        variant="h1"
        fontWeight="700"
        sx={{
          fontFamily: 'Mulish',
          fontSize: '40px',
          lineHeight: '40px',
        }}
      >
        {style3}
      </Typography>
      <Typography
        variant="h1"
        fontWeight="600"
        sx={{
          fontFamily: 'Mulish',
          fontSize: '24px',
          mb:"6px",
          mt: "12px"
        }}
      >
        {style4}
      </Typography>
    </Box>
  );
};

const Cast = ({ name }) => {
    return (
        <Box
        sx={{
          borderRadius: '2px',
          border: '1px solid #DCDCDC',
          display: 'flex',
          padding: '2px 12px 0px 12px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        > 
            <Typography
                variant="1"
                sx={{
                    color: '#000',
                    textAlign: 'center',
                    fontFamily: 'NTR',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                }}
            >
                {name}
            </Typography>
        </Box>
    );
};

const Detail = ({ name }) => {
  return (
      <Box
      mt="10px"
      sx={{
        borderRadius: '2px',
        border: '1px solid #DCDCDC',
        display: 'flex',
        padding: '2px 12px 0px 12px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      > 
          <Typography
              variant="1"
              sx={{
                  color: '#585858',
                  textAlign: 'center',
                  fontFamily: 'NTR',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  letterSpacing: '1.2px',
              }}
          >
              {name}
          </Typography>
      </Box>
  );
};

const SLink = ({ ytmusic, spotify, wynk }) => {
    return (
      <Box display="flex" gap="10px">
        {/* <a href={soundcloud} target="_blank" rel="noopener noreferrer">
          <img src="assets/links/soundcloud.svg" alt="soundcloud" />
        </a> */}
        <a href={spotify} target="_blank" rel="noopener noreferrer">
          <img src="assets/links/spotify.svg" alt="spotify" />
        </a>
        <a href={ytmusic} target="_blank" rel="noopener noreferrer">
          <img src="assets/links/youtube_music.svg" alt="youtube_music" />
        </a>
        <a href={wynk} target="_blank" rel="noopener noreferrer">
          <img src="assets/links/wynk_music.svg" alt="wynk_music" />
        </a>
      </Box>
    );
  };

export {Description, Cast, Detail, SLink}  ;
