import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useState } from 'react';
import { Link } from 'react-router-dom';


import Footer from "../global/footer";
import Header from "../global/header";
import Navbar from "../global/navbar";
import data from "../../data/data.json"
import { Description, SLink, Cast } from "../home/style";

export default function Work() {
  const {
    album
  } = data;

  const [hoveredImage, setHoveredImage] = useState(null);
  const [lastHoveredImage, setLastHoveredImage] = useState(null);

  const handleMouseEnter = (data) => {
    setHoveredImage(data);
    setLastHoveredImage(data);
  }

  const handleMouseLeave = () => {
    setHoveredImage(null);
  }

  return (
    <Box
      maxWidth="1600px"
    >
      <Header />
      <Navbar />
      {/* ItemList*/}

      <Box
        display='flex'
        justifyContent="space-between"
        maxWidth="1600px"
        m="0 auto"
        position="relative"
        mb="50px"
      >
        <Box
          width='50vw'
          maxWidth='800px'
          overflowY='auto'
          m="0 0 0 20px"
        >
          <Box
            p='20px 20px 20px 0px'
            display="grid"
            gridTemplateColumns="repeat(2, minmax(0, 1fr))"
            gap="20px"
            onMouseLeave={() => handleMouseLeave()}
          >
            {album.map((item, index) => (
              <Box key={index}>
                <Card style={{ overflow: 'hidden', boxShadow: '' }} key={index}>
                  <CardContent style={{ padding: 0 }}>
                    <Box
                      style={{
                        width: '100%',
                        maxHeight: '190px',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                      onMouseEnter={() => handleMouseEnter(item)}
                    >
                      <CardMedia
                        sx={{
                          transition: 'transform 1s ease, filter 1s ease',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            filter: 'brightness(0.7)',
                          },
                        }}
                      >
                        <Link key={index} to={`/${item.name}`}>
                          <img
                            key={index}
                            src={item.thumbnail.src}
                            alt={`work ${item.name}`}
                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                          />
                        </Link>

                      </CardMedia>
                      <Box
                        p="4px 10px 1px 10px"
                        backgroundColor='rgba(0, 0, 0, 0.5)'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        position="absolute"
                        bottom="0"
                        left="0"
                        width="auto"
                      >
                        <Typography
                          sx={{
                            color: "#F8F8F8",
                            textAlign: "center",
                            fontFamily: "NTR",
                            fontSize: "14px",
                            letterSpacing: "1.2px",
                            textTransform: "uppercase",
                          }}
                        >
                          Album
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
        {/* HoverDetail */}
        <Box
          display="flex"
          alignItems="center"
          top="100px"
          position="fixed"
          justifyContent="flex-end"
          width="100vw"
          maxWidth="1600px"
          zIndex="-1" // Adjusted zIndex value
        >
          <Box width='700px' m="20px">
            {lastHoveredImage && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                m="0 20px 0 20px"
                right="0px"
                gap="15px"
                p='20px'
              >
                <Description style3={lastHoveredImage.title} />
                <Description style1={lastHoveredImage.description} />
                <Box display="flex" gap="10px">
                  {lastHoveredImage.cast.list.map((castMember, castIndex) => (
                    <Cast key={castIndex} name={castMember} />
                  ))}
                </Box>
                <SLink
                  spotify={lastHoveredImage.links.spotify}
                  ytmusic={lastHoveredImage.links.ytm}
                  wynk={lastHoveredImage.links.wynk}
                />
              </Box>
            )}
          </Box>
        </Box>
        {/* HoverDetail Ends*/}
      </Box>
      {/* ItemList Ends */}
      <Footer />
    </Box>
  )
}
