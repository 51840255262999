import { Box, Typography } from "@mui/material"

const AboutText = ({tagline, moto, about}) => {
    return (
        <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
            <Typography
                mt="30px"
                mb="30px"
                sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "NTR",
                    fontSize: "32px",
                }}   
            >
                {tagline}
            </Typography>
            <Typography
                mb="40px"
                width="800px"
                sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "NTR",
                    fontSize: "64px",
                    lineHeight: "60px"
                }}  
            >
                {moto}
            </Typography>
            <Typography
                mb="40px"
                width="600px"
                sx={{
                    color: "#486284",
                    textAlign: "center",
                    fontFamily: "Mulish",
                    fontSize: "20px",
                    lineHeight: "28px",
                    whiteSpace: 'pre-line'
                }}  
            >
                {about}
            </Typography>
        </Box>
    )
}

export {AboutText};