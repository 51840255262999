import {Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Home from "./scene/home";
import About from "./scene/about";
import Work from "./scene/work";
import Content from "./scene/content";


function App() {
    return (
        <Box
            maxWidth="1600px"
            margin="0 auto"
            overflow="hidden"
        >
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/work" element={<Work />} />
                <Route path="/:name" element={<Content />} />
            </Routes>
        </Box>
    );
}

export default App;


