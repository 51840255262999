import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";


export default function Team () {
    return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="50px"
        >
            <Typography
            color = "#101010"
            fontFamily= "NTR"
            fontSize= "64px"
            lineHeight= "40px" /* 83.333% */
            letterSpacing= "2.4px"   
            textTransform="uppercase"
            >
                Meet Our Team
            </Typography>
            <Box
              gap="50px"
              display="grid" 
              gridTemplateColumns="repeat(3, minmax(0, 1fr))"
            >
              <TeamProfile teamprofilesrc="assets/cast/cast1.jpg" teamprofilealt="teammember1" teamprofilerole="director" teamprofilename="Priyalakshmi Thongam"/>
              <TeamProfile teamprofilesrc="assets/cast/cast2.jpg" teamprofilealt="teammember2" teamprofilerole="asst. director" teamprofilename="Matum Salam"/>
              <TeamProfile teamprofilesrc="assets/cast/cast3.jpg" teamprofilealt="teammember3" teamprofilerole="camera" teamprofilename="Priyalakshmi Thongam"/>
              <TeamProfile teamprofilesrc="assets/cast/cast3.jpg" teamprofilealt="teammember3" teamprofilerole="camera" teamprofilename="Priyalakshmi Thongam"/>
              <TeamProfile teamprofilesrc="assets/cast/cast1.jpg" teamprofilealt="teammember3" teamprofilerole="camera" teamprofilename="Priyalakshmi Thongam"/>
              <TeamProfile teamprofilesrc="assets/cast/cast2.jpg" teamprofilealt="teammember3" teamprofilerole="camera" teamprofilename="Priyalakshmi Thongam"/>
            </Box>
        </Box>
    )
}

const TeamProfile = ({teamprofilesrc, teamprofilealt, teamprofilerole, teamprofilename}) => {
  return (
    <Box>
      <Card style={{ width: '260px', overflow: 'hidden', borderRadius: "0px" }}>
        <CardContent style={{ padding: 0 }}>
          <Box
            style={{
              width: '100%',
              height: '260px',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <CardMedia
            sx={{
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            >
              <img src={teamprofilesrc} alt={teamprofilealt} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
            </CardMedia>
            <Box
              position= 'absolute'
              bottom= '0'
              left= '0'
              p="4px 10px 1px 10px"
              backgroundColor= 'rgba(0, 0, 0, 0.7)'
              display= 'flex'
              justifyContent= 'center'
              alignItems= 'center'
            >
              <Typography 
                sx={{
                  color: "#F8F8F8",
                  textAlign: "center",
                  fontFamily: "NTR",
                  fontSize: "14px",
                  letterSpacing: "1.2px",
                  textTransform: "uppercase",
                }}
              >
                {teamprofilerole}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Typography 
        sx={{
          mt:"20px",
          fontFamily: "NTR",
          fontSize: "20px",
          letterSpacing: "1.2px",
          textTransform: "Capitalize",
        }}
      >
            {teamprofilename}
      </Typography>
    </Box> 
  )
}