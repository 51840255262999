import { Box, Typography } from "@mui/material";

const Text = ({ title, description, title2, other }) => {
    return (
        <Box>
            <Typography
                sx={{
                    width: "700px",
                    color: '#000',
                    display: "block",
                    textAlign: 'center',
                    fontFamily: 'EB Garamond',
                    fontSize: '64px',
                    fontWeight: "800",
                    letterSpacing: '1.2px',
                    lineHeight: "50px"
                }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    width: "700px",
                    color: '#000',
                    textAlign: 'center',
                    fontFamily: 'NTR',
                    fontSize: '24px',
                    letterSpacing: '1.2px',
                    mt: "15px",
                    lineHeight: "30px"
                }}
            >
                {description}
            </Typography>
            <Typography
                sx={{
                    color: "101010",
                    fontFamily: "Poppins",
                    fontSize: "48px",
                    letterSpacing: "2.4px",
                    textAlign: "center",
                    mb: "40px"
                }}
            >
                {title2}
            </Typography>
        </Box>
    )
}

const Platform = ({ ytmusic, spotify, wynk }) => {
    return (
        <Box display="flex" gap="10px">
            <a href={spotify} target="_blank" rel="noopener noreferrer">
                <img src="assets/links/spotify.svg" alt="spotify" />
            </a>
            <a href={ytmusic} target="_blank" rel="noopener noreferrer">
                <img src="assets/links/youtube_music.svg" alt="youtube_music" />
            </a>
            <a href={wynk} target="_blank" rel="noopener noreferrer">
                <img src="assets/links/wynk_music.svg" alt="wynk_music" />
            </a>
        </Box>
    );
};


const Cast = ({ castsrc, castaltname, castname }) => {
    return (
        <Box>
            <Box
                width="100%"
                height="260px"
                overflow="hidden"
            >
                <img src={castsrc} alt={castaltname} style={{ width: '100%' }} />
            </Box>
            <Typography
                sx={{
                    color: "#101010",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    letterSpacing: "0.7px",
                }}
            >
                {castname}
            </Typography>
        </Box>
    )
}

const OtherCast = ({ othercastrole, othercastname }) => {
    return (
        <Box>
            <Typography
                sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    letterSpacing: "1px",
                }}
            >
                {othercastrole}
            </Typography>
            <Typography
                sx={{
                    color: "808080",
                    fonFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "300",
                    letterSpacing: "0.9px",
                }}
            >
                {othercastname}
            </Typography>
            <Div />
        </Box>
    )
}

const Div = ({ }) => {
    return (
        <div style={{
            width: '320px',
            borderBottom: '0.7px solid var(--Black-200, #DCDCDC)'
        }} />
    )
}

export { Cast, OtherCast, Div, Text, Platform };