import { Box } from "@mui/material";
import Footer from "../global/footer";
import Header from "../global/header";
import { AboutText } from "./style";
import Team from "../global/team";
import Contact from "../global/contact";
import Navbar from "../global/navbar";

export default function About() {
    return (
        <Box>    
            <Header/>
            <Navbar/>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                gap="100px"
                >
                <Box 
                    mt="200px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                <img src="assets/h360logo.png" alt="logo" style={{width:"120px"}}/>
                <AboutText 
                    tagline="Bringing lyrics to live" 
                    moto="Unveiling cultural richness through mesmerizing visuals" 
                    about={'360 PICTURES is one of the leading Music video and Movie studio in Manipur Film Industry, Manipur (Kangleipak), India since 2013 which believes in representing our motherland Manipur (Kangleipak)  to the world with its indigenous and ethics through Music, Movie, Video album etc… \n \n Support us by subscribing our channel and help us to make a better Manipur for tomorrow.\n \n With Love And Regards.'}
                />
                </Box>
                <Team/>
                <Contact/>
            </Box>
            <Footer/>
        </Box>
    )
    }