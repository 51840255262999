import { Box } from "@mui/material";

export default function Header() {
  return (
    <Box 
      display="flex"
      justifyContent="flex-end"
      maxWidth="1600px"
      margin="0 auto"
    >
      <Box
        position="fixed"
        zIndex="9999"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        m="20px 40px 0 0"

        sx={{
          width: '50vw',
          maxWidth: '800px',
          width: '100%',
          '@media (min-width: 768px)': {
            width: '70%',
          },
          '@media (min-width: 1206px)': {
            width: 'calc(45% - 20px)',
          },
        }}
      >
        <img src="assets/h360logo.png" alt="logo" style={{ width: '50px' }} />
        {/* <img src="assets/Menu.png" alt="menu"/> */}
      </Box>
    </Box>

  );
}