import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { forwardRef } from "react";

export default function Navbar() {
  const location = useLocation();
  const highlightRef = useRef(null);

  useEffect(() => {
    const activeLinkIndex = ["", "work", "about"].indexOf(location.pathname.split('/')[1]);
    const navbarContentWidth = 100 / 3; // Assuming 3 links evenly spaced
    if (highlightRef.current) {
      highlightRef.current.style.left = `${activeLinkIndex * navbarContentWidth}%`;
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position when component mounts
  }, []);

  return (
    <Box>
      <NavbarBox>
        <NavbarHighlight ref={highlightRef} />
        <NavbarContent>
          <Link to="/" style={{ textDecoration: "none"}} className={location.pathname === "/" ? "active" : ""}>
            <NavbarFont text="Home" active={location.pathname === "/"} />
          </Link>
        </NavbarContent>
        <NavbarContent>
          <Link to="/work" style={{ textDecoration: "none"}} className={location.pathname === "/work" ? "active" : ""}>
            <NavbarFont text="Work" active={location.pathname === "/work"} />
          </Link>
        </NavbarContent>
        <NavbarContent>
          <Link to="/about" style={{ textDecoration: "none"}} className={location.pathname === "/about" ? "active" : ""}>
            <NavbarFont text="About" active={location.pathname === "/about"} />
          </Link>
        </NavbarContent>
      </NavbarBox>
    </Box>
  );
}

const NavbarBox = ({ children }) => {
  return (
    <Box
      position="fixed"
      display="flex"
      bottom="20px"
      left="0"
      right="0"
      m="auto"
      height="36px"
      width="330px"
      backgroundColor="white"
      borderRadius="5px"
      boxShadow="0px 2px 4.8px 0px rgba(0, 0, 0, 0.25)"
      zIndex="9999"
      overflow="hidden"
    >
      {children}
    </Box>
  );
};

const NavbarContent = ({ children }) => {
  return (
    <Box
      flex="1"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
};

const NavbarFont = ({ text, active }) => {
  return (
    <Typography
      sx={{
        color: active ? "white" : "black",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        textTransform: "uppercase",
      }}
    >
      {text}
    </Typography>
  );
};

const NavbarHighlight = forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      width="33.33%" // Assuming 3 links evenly spaced
      backgroundColor="black"
      zIndex="-1" // Ensure the highlight box is behind the content
    />
  );
});