import { Box, Typography, Link } from "@mui/material";

export default function Footer() {
  return (
    <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        p="0 100px 0 100px"
        maxWidth="1600px"
        margin="0 auto 100px auto"
        zIndex="1"
    >
        <Box 
            display="flex"
            justifyContent="left"
            gap="10px"
            width="30%"
        >
            {/* <Link href="https://www.instagram.com/h360pictures/" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                <img src="assets/twitter.png" alt="twitter" style={{ width: '30px' }}/>
            </Link> */}
            <Link href="https://www.instagram.com/h360pictures/" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                <img src="assets/insta.png" alt="insta" style={{ width: '30px' }}/>
            </Link>
            <Link href="https://www.facebook.com/H360PICTURES19/" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                <img src="assets/facebook.png" alt="facebook" style={{ width: '30px' }}/>
            </Link>  
        </Box>
        <Box 
            display="flex"
            justifyContent="center"
            width="40%"
        >
            <img src="assets/h360logo.png" alt="logo" style={{ width: '50px' }}/>
        </Box>
        <Box 
            display="flex"
            justifyContent="right"
            width="30%"
        >
            <Typography
              variant="1"
              display="flex"
              gap="10px"
              sx={{
                  color: '#000',
                  textAlign: 'center',
                  fontFamily: 'NTR',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  letterSpacing: '1.32px',
              }}
            >
            <Link href="" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                2021 @ all rights reserved
            </Link>
            <Link href="" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: "inherit" }}>
                Copyrights
            </Link>
            </Typography>
        </Box>
    </Box>
  );
}