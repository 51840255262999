import { Box, Typography } from "@mui/material";
import Header from "../global/header";
import { Thumbnail1, Thumbnail2 } from "../../component";
import { Description, Detail, Cast, SLink } from "./style";
import Footer from "../global/footer";
import Navbar from "../global/navbar"
import { Link } from "react-router-dom";

import data from "../../data/data.json"
import { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';
import './animation.css';

export default function Home() {
    const { album, home } = data;

    const featuredAlbumNames = ["mapu_pande_haibinu", "hairammu_amuktang", "taningle", "hairo"];
    const featuredAlbums = album.filter(albumItem => featuredAlbumNames.includes(albumItem.name));

    const latestAlbumNames = ["mapu_pande_haibinu", "mapu_pande_haibinu", "mapu_pande_haibinu"];
    const latestAlbums = album.filter(albumItem => latestAlbumNames.includes(albumItem.name));


    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({ threshold: 0.5 });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    return (
        <Box
            maxWidth="1600px"
            margin="0 auto"

        >
            <Header />
            <Navbar />
            {/* Hero Section */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                backgroundColor="black"
                width="100vw"
                height="100vh"
                maxWidth="1600px"
                maxHeight="800px"
                overflow="hidden"
            >
                {/* <video id="1" src={home.vidsrc} autoPlay muted loop style={{ filter: 'blur(10px)', width: "150%" }} />
                <Box
                    position="absolute"
                    width="auto"
                    height="100vh"
                    maxHeight="800px"
                    overflow="hidden"
                >
                    <video id="2" src={home.vidsrc} autoPlay muted loop style={{ height: '100%', width: 'auto' }} />
                </Box> */}
                <img src="assets/thumbnail.MapuPandeHaibinu.jpg" />
                <Box
                    position="absolute"
                    width="100%"
                    bottom="100px"
                    justifyContent="center"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="27" viewBox="0 0 14 27" fill="none">
                        <path d="M6.12375 26.3644C6.31128 26.5519 6.56559 26.6572 6.83075 26.6572C7.09591 26.6572 7.35022 26.5519 7.53775 26.3644L13.1947 20.7074C13.2903 20.6152 13.3664 20.5048 13.4189 20.3828C13.4713 20.2608 13.4988 20.1296 13.5 19.9968C13.5012 19.8641 13.4759 19.7324 13.4256 19.6095C13.3753 19.4866 13.301 19.3749 13.2071 19.281C13.1133 19.1872 13.0016 19.1129 12.8787 19.0626C12.7558 19.0123 12.6241 18.987 12.4913 18.9882C12.3586 18.9893 12.2273 19.0169 12.1053 19.0693C11.9833 19.1217 11.873 19.1979 11.7807 19.2934L7.83075 23.2434L7.90531 1C7.90531 0.734784 7.5 1 7.5 0.5C7.5 0.5 7.26522 0 7 0C6.73478 0 6.68754 0.312464 6.5 0.5C6.31246 0.687536 6.12364 0.734784 6.12364 1L5.83075 23.2434L1.88075 19.2934C1.69215 19.1113 1.43954 19.0105 1.17735 19.0128C0.915152 19.015 0.664338 19.1202 0.47893 19.3056C0.293522 19.491 0.188353 19.7418 0.186074 20.004C0.183796 20.2662 0.28459 20.5188 0.466748 20.7074L6.12375 26.3644Z" fill="white" />
                    </svg>
                    <Typography sx={{
                        zIndex: "100",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        color: "white"
                    }}>
                        Scroll
                    </Typography>
                </Box>

            </Box>
            {/* Hero Section Ends */}
            {/* Brief */}
            <Box
                ref={ref}
                display="flex"
                justifyContent="center"
                backgroundColor="#FCFCFC"
                flexDirection="column"
                alignItems="center"
                className={`animated-element ${isVisible ? 'visible' : ''}`}
            >
                <Typography
                    variant="1"
                    sx={{
                        width: "1000px",
                        color: '#000',
                        display: "block",
                        textAlign: 'center',
                        fontFamily: 'EB Garamond',
                        fontSize: '64px',
                        fontWeight: "800",
                        letterSpacing: '1.2px',
                        lineHeight: "50px",
                        m: "100px 0 20px 0"
                    }}
                >
                    {home.company}
                </Typography>
                <Typography
                    variant="1"
                    sx={{
                        width: "700px",
                        color: '#000',
                        display: "block",
                        textAlign: 'center',
                        fontFamily: 'NTR',
                        fontSize: '24px',
                        letterSpacing: '1.2px',
                        lineHeight: "50px",
                        mb: "100px"
                    }}
                >
                    {home.tagline}
                </Typography>
            </Box>
            {/* Brief Ends */}
            {/* Featured List */}
            {featuredAlbums.map((albumItem, index) => (
                <Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Box width="50%">
                        <Link to={`/${albumItem.name}`}>
                            <Thumbnail1 src={albumItem.thumbnail.src} title={albumItem.title} />
                        </Link>
                    </Box>
                    <Box
                        width="43%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        m="0 40px 0 20px"
                        right="0px"
                        gap="15px"
                    >
                        <Typography
                            sx={{
                                color: " #101010",
                                fontFamily: "NTR",
                                fontSize: "48px",
                                lineHeight: "40px",
                                fontWeight: "700",
                                letterSpacing: "2.4px",
                            }}
                        >
                            {albumItem.title}
                        </Typography>
                        <Description style1={albumItem.description} />
                        <Box
                            display="flex"
                            gap="10px"
                        >
                            {albumItem.cast.list.map((member, idx) => (
                                <Cast key={idx} name={member} />
                            ))}
                        </Box>
                        <SLink
                            spotify={albumItem.links.spotify}
                            ytmusic={albumItem.links.ytm}
                            wynk={albumItem.links.wynk}
                        />
                    </Box>
                </Box>
            ))}
            {/* Featured List Ends */}
            {/* Banner */}
            <Box
                mt="100px"
                display="flex"
                justifyContent="center"
            >
                <Typography
                    variant="1"
                    sx={{
                        width: "700px",
                        color: '#000',
                        display: "block",
                        textAlign: 'center',
                        fontFamily: 'NTR',
                        fontSize: '40px',
                        letterSpacing: '1.2px',
                        lineHeight: "50px",
                    }}
                >
                    {home.tagline}
                </Typography>
            </Box>
            {/* Banner Ends*/}
            {/* Latest */}
            {latestAlbums.map((albumItem, index) => (
                <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="start"
                    flexDirection="row"
                    mt="100px"
                    mb="50px"
                    gap="50px"
                >
                    <Box width="300px">
                        <a href={albumItem.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                            <Thumbnail2 src={albumItem.teaser.src} title={albumItem.title} sx={{ mb: '10px', }} />
                        </a>
                        <Box
                            display="flex"
                            gap="10px"
                        >
                            <Detail name="Teaser" />
                            <Detail name={albumItem.teaser.date} />
                        </Box>
                        <Description style4={albumItem.title} />
                        <Description style2="Watch to unveil a taste of the forthcoming album" />
                    </Box>
                    <Box width="300px">
                        <a href={albumItem.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                            <Thumbnail2 src={albumItem.thumbnail.src} title={albumItem.title} sx={{ mb: '10px', }} />
                        </a>
                        <Box
                            display="flex"
                            gap="10px"
                        >
                            <Detail name="Album" />
                            <Detail name={albumItem.thumbnail.date} />
                        </Box>
                        <Description style4={albumItem.title} />
                        <Description style2="Check out our latest album" />
                    </Box>
                    <Box width="300px">
                        <a href={albumItem.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                            <Thumbnail2 src={albumItem.bts.src} title={albumItem.title} sx={{ mb: '10px', }} />
                        </a>
                        <Box
                            display="flex"
                            gap="10px"
                        >
                            <Detail name="BTS" />
                            <Detail name={albumItem.bts.date} />
                        </Box>
                        <Description style4={albumItem.title} />
                        <Description style2="Take a glimpse into the creative process" />
                    </Box>
                </Box>
            ))}
            {/* Latest ends */}
            <Footer />
        </Box>
    )
}