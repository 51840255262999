import { useState } from 'react';
import { Box, CardMedia, Card } from "@mui/material";

const Thumbnail1 = ({ src }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
        sx={{
          borderRadius: "0px",
        position: 'relative',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardMedia
        component="img"
        image={src}// Replace with your image URL
        alt="Your Image"
        sx={{
          position: 'relative',
          transition: 'transform 1s ease, filter 1s ease',
          '&:hover': {
            transform: 'scale(1.1)',
            filter: 'brightness(0.7)',
          },
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          left: '70%',
          top: '50%',
          padding: '0 20px',
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          transition: 'opacity 1s ease, transform 1s ease',
          opacity: isHovered ? 1 : 0,
          transform: isHovered ? 'translate(-50%, -50%) translateX(100px)' : 'translate(-50%, -50%)',
          pointerEvents: "none"
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="95" height="75" viewBox="0 0 19 15" fill="none">
          <path d="M17.7171 8.21848C17.998 7.93723 18.1558 7.55598 18.1558 7.15848C18.1558 6.76098 17.998 6.37973 17.7171 6.09848L12.0611 0.439481C11.7797 0.158086 11.398 -4.19311e-09 11.0001 0C10.6021 4.19311e-09 10.2205 0.158086 9.93908 0.439481C9.65769 0.720875 9.4996 1.10253 9.4996 1.50048C9.4996 1.89843 9.65769 2.28009 9.93908 2.56148L13.0351 5.65848L1.50008 5.65848C1.10226 5.65848 0.720727 5.81652 0.439423 6.09782C0.158118 6.37912 8.2016e-05 6.76066 8.2016e-05 7.15848C8.2016e-05 7.55631 0.158118 7.93784 0.439423 8.21914C0.720727 8.50045 1.10226 8.65848 1.50008 8.65848L13.0351 8.65848L9.93908 11.7545C9.79975 11.8938 9.68923 12.0592 9.61382 12.2413C9.53841 12.4233 9.4996 12.6184 9.4996 12.8155C9.4996 13.0125 9.53841 13.2076 9.61382 13.3897C9.68923 13.5717 9.79975 13.7371 9.93908 13.8765C10.0784 14.0158 10.2438 14.1263 10.4259 14.2017C10.6079 14.2771 10.803 14.316 11.0001 14.316C11.1971 14.316 11.3922 14.2771 11.5743 14.2017C11.7563 14.1263 11.9217 14.0158 12.0611 13.8765L17.7171 8.21848Z" fill="white" />
        </svg>
      </Box>
    </Card>
  );
}

const Thumbnail2 = ({ src }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
        sx={{
        position: 'relative',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardMedia
        component="img"
        image={src}// Replace with your image URL
        alt="Your Image"
        sx={{
          position: 'relative',
          transition: 'transform 1s ease',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          left: '70%',
          top: '50%',
          padding: '0 5px',
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          transition: 'opacity 1s ease, transform 1s ease',
          opacity: isHovered ? 1 : 0,
          transform: isHovered ? 'translate(-50%, -50%) translateX(40px)' : 'translate(-50%, -50%)',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="30" viewBox="0 0 19 15" fill="none">
          <path d="M17.7171 8.21848C17.998 7.93723 18.1558 7.55598 18.1558 7.15848C18.1558 6.76098 17.998 6.37973 17.7171 6.09848L12.0611 0.439481C11.7797 0.158086 11.398 -4.19311e-09 11.0001 0C10.6021 4.19311e-09 10.2205 0.158086 9.93908 0.439481C9.65769 0.720875 9.4996 1.10253 9.4996 1.50048C9.4996 1.89843 9.65769 2.28009 9.93908 2.56148L13.0351 5.65848L1.50008 5.65848C1.10226 5.65848 0.720727 5.81652 0.439423 6.09782C0.158118 6.37912 8.2016e-05 6.76066 8.2016e-05 7.15848C8.2016e-05 7.55631 0.158118 7.93784 0.439423 8.21914C0.720727 8.50045 1.10226 8.65848 1.50008 8.65848L13.0351 8.65848L9.93908 11.7545C9.79975 11.8938 9.68923 12.0592 9.61382 12.2413C9.53841 12.4233 9.4996 12.6184 9.4996 12.8155C9.4996 13.0125 9.53841 13.2076 9.61382 13.3897C9.68923 13.5717 9.79975 13.7371 9.93908 13.8765C10.0784 14.0158 10.2438 14.1263 10.4259 14.2017C10.6079 14.2771 10.803 14.316 11.0001 14.316C11.1971 14.316 11.3922 14.2771 11.5743 14.2017C11.7563 14.1263 11.9217 14.0158 12.0611 13.8765L17.7171 8.21848Z" fill="white" />
        </svg>
      </Box>
    </Card>
  );
}

export {Thumbnail1, Thumbnail2};
